import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../@components/UI";
import { useHistory } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import CardSkeletonList from "./CardSkeletonList";
import MintingItem from "./MintingList";
import { useTranslation } from "react-i18next";
import maticIcon from "../../../static/matic-icon-white.png";

//import { makeStyles } from "@mui/styles";
// const useStyles = makeStyles((theme) => ({
//   shakeAnimation: {
//     animation: `$shake 6s ease infinite`, // Total duration includes shake time and pause
//   },
//   "@keyframes shake": {
//     "0%, 100%": { transform: "skewY(0deg)" },
//     "2.5%": { transform: "skewY(6deg)" },
//     "5%": { transform: "skewY(-6deg)" },
//     "7.5%": { transform: "skewY(6deg)" },
//     "10%": { transform: "skewY(-6deg)" },
//     "12.5%": { transform: "skewY(6deg)" },
//     "15%": { transform: "skewY(-6deg)" },
//     "17.5%, 100%": { transform: "skewY(0deg)" }, // Remain still for the rest of the time
//   },
// }));

const CardList = ({
  items,
  mintingItems,
  busy,
  onTransferTicket,
  onGenerateQrTicket,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} columns={12} sx={{ ml: { md: "20px" } }}>
      {busy && <CardSkeletonList />}
      {mintingItems?.length > 0 &&
        mintingItems?.map((item, index) => (
          <MintingItem item={item} key={"collection_" + index} />
        ))}
      {items?.length > 0 &&
        items
          .slice(0, 100)
          ?.map((item, index) => (
            <Item
              item={item}
              key={item.id}
              onTransferTicket={onTransferTicket}
              onGenerateQrTicket={onGenerateQrTicket}
            />
          ))}
      {!busy && items && items.length === 0 && (
        <Grid
          item
          mobile={12}
          md={12}
          lg={12}
          sx={{ display: "flex", justifyContent: "center", }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", }}>
            <UI.NoResults text={t("profile.home.noNftsCollected")} />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

const Item = ({ item, onTransferTicket, onGenerateQrTicket }) => {
  return (
    <Grid
      item
      mobile={12}
      md={4}
      lg={4}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <UI.FlipCard
        frontComponent={<FrontSide item={item} />}
        backComponent={
          <BackSide
            item={item}
            onTransferTicket={onTransferTicket}
            onGenerateQrTicket={onGenerateQrTicket}
          />
        }
      />
    </Grid>
  );
};

const BackSide = ({ item }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  return (
    <Box
      sx={{
        cursor: "pointer",
        textAlign: "left",
        margin: "0 auto",
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        opacity: 1,
      }}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          borderBottom: "none",
          padding: "14px 20px 10px",
          height: "70px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: "flex", alignItems: "center", mr: "12px" }}>
            <Avatar src={item?.dao_info?.avatar_url} alt={"avatar"} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mt: "2px" }}>
            <Box sx={{ textAlign: "left" }}>
              {item?.dao_info.name ? (
                <Typography
                  variant={"subtitle4"}
                  sx={{
                    maxWidth: "195px",
                  }}
                >
                  <UI.OverflowTip>{`${item?.dao_info?.name} `}</UI.OverflowTip>
                </Typography>
              ) : (
                <Skeleton
                  height={"30px"}
                  width={"130px"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            {item?.nft_name ? (
              <Typography
                variant={"h2"}
                sx={{
                  fontSize: "15px !important",
                  maxWidth: "195px",
                }}
              >
                <UI.OverflowTip>{`${
                  item?.nft_name_slo !== null &&
                  item?.nft_name_slo !== "" &&
                  item?.nft_name_slo !== undefined &&
                  i18n.language === "sl"
                    ? item?.nft_name_slo
                    : item?.nft_name || ""
                } #${item?.nft_id}`}</UI.OverflowTip>
              </Typography>
            ) : (
              <Skeleton
                height={"30px"}
                width={"130px"}
                variant={"rectangular"}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          height: "290px",
          flexDirection: "column",
          p: "0px 20px 10px",
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
        }}
      >
        <Box sx={{ display: "flex", mt: "10px" }}>
          <Typography
            variant={"subtitle4"}
            sx={{
              mr: "10px",
            }}
          >
            {t("commons.price")}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {item?.free_nft_gas_fee_payed_by_system === false && (
              <Box sx={{ width: "18px", height: "18px" }}>
                <UI.PaymentIcon currency={item?.currency} />
              </Box>
            )}
            {item?.nft_price !== "0.00" && (
              <Typography variant={"h8"} sx={{ ml: "7px" }}>
                {`${item?.nft_price} ${item?.currency}`}
              </Typography>
            )}
            {item?.nft_price === "0.00" && (
              <Typography variant={"h8"} sx={{ ml: "7px" }}>
                {t("commons.free")}
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex", mt: "10px", maxHeight: "141px" }}>
          <Typography
            variant={"subtitle4"}
            sx={{
              maxHeight: "141px",
              overflow: "hidden",
              whiteSpace: "normal",
              textAlign: "justify",
              textOverflow: "ellipsis",
            }}
          >
            {item?.description_slo !== null &&
            item?.description_slo !== "" &&
            item?.description_slo !== undefined &&
            i18n.language === "sl"
              ? item?.description_slo?.length > 240
                ? item?.description_slo?.slice(0, 240) + " ..."
                : item?.description_slo
              : item?.description?.length > 240
              ? item?.description?.slice(0, 240) + " ..."
              : item?.description}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", mt: "20px" }}>
          <Box
            sx={{
              display: "flex",
              bottom: "16px",
              right: "16px",
            }}
          >
            <UI.UtilityTag
              utilityType={
                item.utilities?.find(
                  (utility) => utility.is_main_utility === true
                )?.type || ""
              }
            />
            {item?.is_imported && (
              <UI.Tag
                sx={{
                  ml: "10px",
                  backgroundColor: (theme) => theme.palette.tag.green,
                }}
              >
                {t("utilityTag.imported")}
              </UI.Tag>
            )}

            {item.nft_amount_limit < 999999 && (
              <UI.Tag
                sx={{
                  ml: "10px",
                  backgroundColor: (theme) => theme.palette.tag.green,
                }}
              >
                {t("utilityTag.limited")}
              </UI.Tag>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "70px",
          p: "5px 10px",
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
        }}
      >
        <Box sx={{ width: "170px" }}>
          <UI.Button
            sx={{ height: "36px" }}
            type={"primary"}
            title={t("buttons.viewMyNFT")}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              history.push({
                pathname: `/profile/nft/${item?.id}`,
                state: { scrollToTop: true },
              });
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const FrontSide = ({ item }) => {
  //const classes = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <Box
      sx={{
        textAlign: "left",
        cursor: "pointer",
        margin: "0 auto",
        width: "290px",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          borderBottom: "none",
          padding: "14px 20px 10px",
          height: "70px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: "flex", alignItems: "center", mr: "12px" }}>
            <Avatar src={item?.dao_info?.avatar_url} alt={"avatar"} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mt: "2px" }}>
            <Box>
              {item?.dao_info.name ? (
                <Typography
                  variant={"subtitle4"}
                  sx={{
                    maxWidth: "195px",
                  }}
                >
                  <UI.OverflowTip>{`${item?.dao_info.name}`}</UI.OverflowTip>
                </Typography>
              ) : (
                <Skeleton
                  height={"30px"}
                  width={"130px"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            {item?.nft_name ? (
              <Typography
                variant={"h2"}
                sx={{
                  fontSize: "15px !important",
                  maxWidth: "195px",
                }}
              >
                <UI.OverflowTip>{`${
                  item?.nft_name_slo !== null &&
                  item?.nft_name_slo !== "" &&
                  item?.nft_name_slo !== undefined &&
                  i18n.language === "sl"
                    ? item?.nft_name_slo
                    : item?.nft_name || ""
                } #${item?.nft_id}`}</UI.OverflowTip>
              </Typography>
            ) : (
              <Skeleton
                height={"30px"}
                width={"130px"}
                variant={"rectangular"}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: "290px",
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          component={"span"}
          sx={{
            boxSizing: "border-box",
            display: "block",
            overflow: "hidden",
            width: "initial",
            height: "initial",
            background: "none",
            opacity: 1,
            border: "0px",
            margin: "0px",
            padding: "0px",
            position: "absolute",
            inset: 0,
            lineHeight: "0",
            fontSize: "0",
          }}
        >
          {item ? (
            item.image_file_type?.startsWith("video") ? (
              <video
                key={`${item.id}`} // Change key to force re-render
                playsInline
                width="100%"
                height={"auto"}
                autoPlay="autoplay"
                loop
                muted
              >
                <source src={item.nft_image_url} />
              </video>
            ) : (
              <UI.ImageWithSkeleton
                sx={{
                  position: "absolute",
                  inset: 0,
                  boxSizing: "border-box",
                  padding: 0,
                  border: "none",
                  margin: "auto",
                  display: "block",
                  width: 0,
                  height: 0,
                  minWidth: "100%",
                  maxWidth: "100%",
                  minHeight: "100%",
                  maxHeight: "100%",
                  objectFit: "cover",
                }}
                src={item.nft_image_url}
                width={"100%"}
                alt={"slider-item"}
              />
            )
          ) : (
            <Skeleton
              height={"300px"}
              width={"300px"}
              variant={"rectangular"}
            />
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            position: "absolute",
            bottom: "16px",
            right: "16px",
          }}
        >
          <UI.UtilityTag
            utilityType={
              item?.utilities?.find(
                (utility) => utility.is_main_utility === true
              )?.type || ""
            }
          />
          {item?.is_imported && (
            <UI.Tag
              sx={{
                ml: "10px",
                backgroundColor: (theme) => theme.palette.tag.green,
              }}
            >
              {t("utilityTag.imported")}
            </UI.Tag>
          )}

          {item.nft_amount_limit < 999999 && (
            <UI.Tag
              sx={{
                ml: "10px",
                backgroundColor: (theme) => theme.palette.tag.green,
              }}
            >
              {t("utilityTag.limited")}
            </UI.Tag>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: "20px",
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          height: "70px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", height: "70px" }}>
          {item?.free_nft_gas_fee_payed_by_system === false &&
            item?.full_price !== "0.00" && (
              <Box sx={{ width: "20px", height: "20px", ml: "10px" }}>
                <UI.PaymentIcon currency={item?.currency || "MATIC"} />
              </Box>
            )}
          {item?.full_price !== "0.00" && (
            <Typography
              variant={"h8"}
              sx={{
                ml: "10px",
              }}
            >
              {`${item?.full_price}`}
            </Typography>
          )}
          {item?.full_price === "0.00" && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                //className={classes.shakeAnimation}
                variant={"h10"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "68px",
                  height: "22px",
                  borderRadius: "100px",
                  backgroundColor: (theme) => theme.palette.tag.red,
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                {item?.free_nft_gas_fee_payed_by_system === false && (
                  <Box
                    sx={{
                      width: "12px",
                      height: "12px",
                      mr: "4px",
                      ml: "-2px",
                    }}
                    component={"img"}
                    src={maticIcon}
                  />
                )}
                {t("commons.free")}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CardList;

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useHistory, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import { useUserRole } from "./context";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  categoryItem: {
    width: "180px",
    height: "40px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    background: ({ selected }) => {
      return selected
        ? theme.palette.bgTransparent.secondary
        : theme.palette.bgTransparent.tertiary;
    },
    marginLeft: "2px",
    marginRight: "2px",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
  },
}));

const MobileMenu = ({ DAO, currentUrl }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { loggedInUser } = useUserRole();

  const swiperRef = React.useRef(null);

  const initialSlideToMenuItem = () => {
    if (location.pathname === `${currentUrl}/marketplace`) {
      swiperRef.current.swiper.slideTo(4);
    } else {
      swiperRef.current.swiper.slideTo(0);
    }
  };

  React.useEffect(() => {
    initialSlideToMenuItem();

    // eslint-disable-next-line
  }, [DAO, loggedInUser?.email_verified]);

  return (
    <Box
      sx={{
        mt: "0px",
        mb: "30px",
        px: "10px",
        display: {
          mobile: "block",
          md: "none",
        },
      }}
    >
      <Swiper
        ref={swiperRef}
        grabCursor={true}
        className="collection-category-swiper"
        spaceBetween={10}
        slidesPerView={3.3}
        breakpoints={{
          300: {
            slidesPerView: 3.2,
            spaceBetween: 10,
          },
          400: {
            slidesPerView: 3.5,
            spaceBetween: 20,
          },
          600: {
            slidesPerView: 3.6,
            spaceBetween: 10,
          },
          800: {
            slidesPerView: 3.7,
            spaceBetween: 10,
          },
        }}
      >
        <SwiperSlide key={0}>
          <Item
            selected={location.pathname === `/brand/${DAO?.id}`}
            onSelect={() => {
              swiperRef.current.swiper.slideTo(0);
              history.push(`${currentUrl}`);
            }}
            index={0}
            title={t("brands.menu.home")}
          />
        </SwiperSlide>
        <SwiperSlide key={1}>
          <Item
            selected={location.pathname.split("/")[3] === `feed`}
            onSelect={() => {
              swiperRef.current.swiper.slideTo(1);
              history.push(`${currentUrl}/feed`);
            }}
            index={1}
            title={t("brands.menu.feed")}
          />
        </SwiperSlide>

        <SwiperSlide key={2}>
          <Item
            selected={location.pathname.split("/")[3] === `collection`}
            onSelect={() => {
              swiperRef.current.swiper.slideTo(2);
              history.push(`${currentUrl}/collection`);
            }}
            index={2}
            title={t("brands.menu.collections")}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Item
            selected={location.pathname.split("/")[3] === `album`}
            onSelect={() => {
              swiperRef.current.swiper.slideTo(3);
              history.push(`${currentUrl}/album`);
            }}
            index={3}
            title={t("brands.menu.albums")}
          />
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};

const Item = (props) => {
  const { selected, index, onSelect, title } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const classes = useStyles({ selected, isMobile });
  const theme = useTheme();
  return (
    <Box
      className={classes.categoryItem}
      sx={{
        position: "relative",
        background: selected
          ? theme.palette.bgTransparent.secondary
          : theme.palette.bgTransparent.tertiary,
      }}
      onClick={() => onSelect(index)}
      {...props}
    >
      <Typography
        sx={{
          margin: "0 auto",
          fontWeight: "bold",
          fontSize: "12px !important",
        }}
      >
        {title.toUpperCase()}
      </Typography>
    </Box>
  );
};

export default MobileMenu;

import React from "react";
import useService from "./useService";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import UI from "../@components/UI";
import { useHistory } from "react-router-dom";
import Busy from "../@components/UI/busy";
import CustomHooks from "../@components/hooks";

const BigList = () => {
  const service = useService();

  const { data: daos, isLoading: loading } = CustomHooks.useFetch(
    ["brands"],
    service.list
  );

  return (
    <Grid container spacing={4} columns={20}>
      {loading && (
        <Grid key={1} item mobile={20} md={20}>
          <Busy.Indicator />
        </Grid>
      )}
      {/*{qvrseDao && !loading && <DAO key={99} dao={qvrseDao} />}*/}
      {daos &&
        daos.length > 0 &&
        daos?.map((data, index) => <Item key={"DAO_" + index} DAO={data} />)}

      {!loading && daos && daos.length === 0 && (
        <Grid key={1} item mobile={20} md={20} lg={20} xxl={20}>
          <UI.NoResults text={"No brands found."} />
        </Grid>
      )}
    </Grid>
  );
};

const Item = ({ DAO }) => {
  const history = useHistory();
  return (
    <Grid item mobile={20} tablet={10} md={4} lg={4} xxl={3.33}>
      <Box
        sx={{
          cursor: "pointer",
        }}
        onClick={() =>
          history.push({
            pathname: DAO.id === 1 ? `/qvrse` : `/brand/${DAO.id}`,
            state: { scrollToTop: true },
          })
        }
      >
        <Box
          sx={{
            position: "relative",
            width: "140px",
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
            height: "140px",
            overflow: "hidden",
            borderRadius: "50%",
            border: "2px solid transparent",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              boxShadow: "#944dff 0px 0px 15px 0px",
              border: "2px solid #944dff",
              transform: "scale(1.1)",
              transition: "all 0.3s ease-in-out",
            },
          }}
        >
          <UI.ImageWithSkeleton
            sx={{
              position: "absolute",
              width: "140px",
              height: "140px",
              textAlign: "center",
              objectFit: "cover",
            }}
            width={140}
            src={DAO.avatar_url}
            alt={"dao avatar"}
          />
        </Box>
        <Box
          sx={{
            mt: "14px",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography
            variant={"h8"}
            sx={{
              margin: "0 auto",
              maxWidth: "320px",
              pb: "40px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {DAO.name}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default BigList;
